<template>
  <div class="title-box">
    <div class="flex-center" v-if="isBack">
      <i class="el-icon-arrow-left goback" @click="goback">返回</i>
      <div class="iconTem"></div>
    </div>
    <div class="titleTem">{{ title }}</div>
  </div>
</template>

<script>
export default {
  // 模块名字
  name: 'templateTitle',
  props: {
    isBack: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    goback() {
      this.$emit('handleBack')
    }
  }
}
</script>
<style lang="scss" scoped>
.title-box {
  margin-left: 0.125rem;
  padding: 0.125rem;
  display: flex;
  align-items: center;

  .iconTem {
    width: 0.0125rem;
    height: 0.3rem;
    background: #d8d8d8;
    border-radius: 0.05rem;
    margin: 0 0.125rem;
  }

  .titleTem {
    height: 0.25rem;
    font-size: 0.225rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #212a33;
    line-height: 0.225rem;
  }

  .goback {
    cursor: pointer;
    font-size: 0.2rem;
  }
}
</style>
